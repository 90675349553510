<template>
    <div>
        <div v-if="navMenus.length<=0" style="padding:20px;">
            <el-alert title="无子集菜单" center type="info" :closable="false"></el-alert>
        </div>
        <div v-for="navMenu in navMenus" v-bind:key="navMenu">
            <el-menu-item v-if="!hasChildren(navMenu)" :index="navMenu.path" :router="true">
                <a v-if="navMenu.meta&&navMenu.meta.type==3" :href="navMenu.path" target="_blank" @click.stop='a'></a>
                <i v-if="navMenu.meta&&navMenu.meta.icon" :class="navMenu.meta.icon || 'el-icon-menu'" style="margin-right: 5px;width: 24px;text-align: center;font-size: 18px;vertical-align: middle;"></i>
                <template #title>
                    <span>{{navMenu.meta.title}}</span>
                </template>
            </el-menu-item>
            <el-submenu v-else :index="navMenu.path">
                <template #title>
                    <i v-if="navMenu.meta&&navMenu.meta.icon" :class="navMenu.meta.icon || 'el-icon-menu'" style="margin-right: 5px;width: 24px;text-align: center;font-size: 18px;vertical-align: middle;"></i>
                    <span>{{navMenu.meta.title}}</span>
                </template>
                <NavMenu :navMenus="navMenu.children"></NavMenu>
            </el-submenu>
        </div>
    </div>
</template>

<script>
export default {
    name: "NavMenu",
    props: ["navMenus"],
    data() {
        return {};
    },
    methods: {
        a() {},
        hasChildren(item) {
            var flag = true;
            if (item.children) {
                if (item.children.every((item) => item.meta.hidden)) {
                    flag = false;
                }
            } else {
                flag = false;
            }
            return flag;
        },
    },
};
</script>
<style>
.el-menu-item [class^="fa-"] {
    margin-right: 5px;
    width: 24px;
    text-align: center;
    font-size: 18px;
    vertical-align: middle;
}
</style>