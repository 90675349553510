<template>
    <div class="user-bar">
        <!-- 全屏 start -->
        <div class="screen panel-item hidden-sm-and-down" @click="screen">
            <i class="el-icon-full-screen"></i>
        </div>
        <!-- 全屏 end -->
        <!-- 消息中心 start -->
        <!-- <div class="msg panel-item" @click="showMsg">
            <el-badge :hidden="msgList.length==0" :value="msgList.length" class="badge" type="danger">
                <i class="el-icon-chat-dot-round"></i>
            </el-badge>
            <el-drawer title="新消息" v-model="msg" :size="400" append-to-body destroy-on-close>
                <el-container>
                    <el-main class="nopadding">
                        <el-scrollbar>
                            <ul class="msg-list">
                                <li v-for="item in msgList" v-bind:key="item.id">
                                    <a :href="item.link" target="_blank">
                                        <div class="msg-list__icon">
                                            <el-badge is-dot type="danger">
                                                <el-avatar :size="40" :src="item.avatar"></el-avatar>
                                            </el-badge>
                                        </div>
                                        <div class="msg-list__main">
                                            <h2>{{item.title}}</h2>
                                            <p>{{item.describe}}</p>
                                        </div>
                                        <div class="msg-list__time">
                                            <p>{{item.time}}</p>
                                        </div>
                                    </a>
                                </li>
                                <el-empty v-if="msgList.length==0" description="暂无新消息" :image-size="100"></el-empty>
                            </ul>
                        </el-scrollbar>
                    </el-main>
                    <el-footer>
                        <el-button type="primary" size="small">消息中心</el-button>
                        <el-button size="small" @click="markRead">全部设为已读</el-button>
                    </el-footer>
                </el-container>
            </el-drawer>
        </div> -->
        <!-- 消息中心 end -->
        <!-- 组织选择 start -->
        <div class="panel-item" @click="dialogVisible=true;getOrgInfo();">
            <div class="user">
                <label style="font-size:1.2rem;">{{orgName}}</label>
            </div>
            <el-dialog title="切换组织" v-model="dialogVisible" width="30%">
                <el-tree ref="org" :default-checked-keys="defaultCheckedKey" :data="orgInfo" node-key="Id" draggable highlight-current :expand-on-click-node="false" :check-strictly="true" :props="defaultProps" @check-change="handleNodeClick" @node-click="handleNodeClick" show-checkbox></el-tree>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button @click="dialogVisible = false">取 消</el-button>
                        <el-button type="primary" @click="dialogVisible = false;BtnSelectOrg();">确 定</el-button>
                    </span>
                </template>
            </el-dialog>
        </div>
        <!-- 组织选择 end -->
        <!-- 个人信息 start -->
        <el-dropdown trigger="click" @command="handleUser" style="height: 100%;">
            <div class="user panel-item">
                <el-avatar :size="30" :src="AdminAvatar"></el-avatar>
                <label> {{ userName }}<i class="el-icon-arrow-down el-icon--right"></i></label>
            </div>
            <template #dropdown>
                <el-dropdown-menu>
                    <el-dropdown-item command="uc">个人设置</el-dropdown-item>
                    <!-- <el-dropdown-item command="cmd">CMD</el-dropdown-item> -->
                    <el-dropdown-item divided command="outLogin">退出登录</el-dropdown-item>
                </el-dropdown-menu>
            </template>
        </el-dropdown>
        <!-- 个人信息 end -->
    </div>
</template>

<script>
import config from "@/config";
import axios from "@/utils/request";
import { ElNotification } from "element-plus";
export default {
    data() {
        return {
            userName: "",
            userNameF: "",
            AdminAvatar: "",
            msg: false,
            dialogVisible: false, //对话框展示
            options: [],
            orgName: "",
            msgList: [
                {
                    id: 1,
                    type: "user",
                    avatar: "/img/avatar.jpg",
                    title: "Skuya",
                    describe: "如果喜欢就点个星星支持一下哦",
                    link: "https://gitee.com/lolicode/scui",
                    time: "5分钟前",
                },
                {
                    id: 2,
                    type: "user",
                    avatar: "/img/avatar2.gif",
                    title: "Lolowan",
                    describe: "点进去Gitee获取最新开源版本",
                    link: "https://gitee.com/lolicode/scui",
                    time: "14分钟前",
                },
                {
                    id: 3,
                    type: "system",
                    avatar: "/img/logo.png",
                    title: "感谢登录SCUI Admin",
                    describe:
                        "Vue 3.0 + Vue-Router 4.0 + ElementPlus + Axios 后台管理系统。",
                    link: "https://gitee.com/lolicode/scui",
                    time: "2020年7月24日",
                },
            ],
            orgInfo: [], //组织信息
            //组织显示信息
            defaultProps: {
                label: (data) => {
                    return data.OrgName;
                },
                children: (data) => {
                    return data.Children;
                },
            },
            //默认选中
            defaultCheckedKey: [],
        };
    },
    created() {
        var adminInfo = this.$TOOL.data.get("adminInfo").AdminInfo;
        this.userName = adminInfo.AdminName;
        this.userNameF = this.userName.substring(0, 1);
        this.AdminAvatar = adminInfo.AdminAvatar;
        this.orgName = adminInfo.AdminOrgName;
    },
    methods: {
        //
        handleClose() {},
        //个人信息
        handleUser(command) {
            if (command == "uc") {
                this.$router.push({ path: "/usercenter" });
            }
            if (command == "cmd") {
                this.$router.push({ path: "/cmd" });
            }
            if (command == "outLogin") {
                this.$confirm("确认是否退出当前用户？", "提示", {
                    type: "warning",
                    confirmButtonText: "退出",
                    confirmButtonClass: "el-button--danger",
                })
                    .then(() => {
                        this.$router.replace({ path: "/login" });
                    })
                    .catch(() => {
                        //取消退出
                    });
            }
        },
        //全屏
        screen() {
            var element = document.documentElement;
            this.$TOOL.screen(element);
        },
        //显示短消息
        showMsg() {
            this.msg = true;
        },
        //标记已读
        markRead() {
            this.msgList = [];
        },
        //获取组织信息
        getOrgInfo() {
            var that = this;
            axios
                .get(`${config.API_URL}/Org/GetOrgListByAdminId`)
                .then((res) => {
                    axios
                        .post(
                            `${config.API_URL}/PersonCenter/GetPersonOrgInfo`,
                            {}
                        )
                        .then((r) => {
                            this.defaultCheckedKey = [];
                            this.defaultCheckedKey.push(r.DefaultOrgId);
                            this.orgInfo = res;
                        });
                });
        },
        //确认选中组织
        BtnSelectOrg() {
            var orgId = this.$refs.org.getCheckedKeys();
            if (orgId[0] != this.defaultCheckedKey[0]) {
                console.log("orgId", orgId);
            }
            axios
                .post(
                    `${config.API_URL}/PersonCenter/SwitchOrg/${orgId[0]}`,
                    {}
                )
                .then((res) => {
                    console.log(res);
                    this.orgName = res;
                    var adminInfo = this.$TOOL.data.get("adminInfo");
                    adminInfo.AdminInfo.AdminOrgId = orgId[0];
                    adminInfo.AdminInfo.AdminOrgName = res;
                    this.$TOOL.data.set("adminInfo", adminInfo);
                    ElNotification.success({
                        title: "操作成功",
                        message: "操作成功！",
                    });
                });
        },
        //组织信息
        handleNodeClick(data, node) {
            if (!node.checked) {
                this.$refs.org.setCheckedKeys([data.Id]);
            }
        },
    },
};
</script>

<style scoped>
.user-bar {
    display: flex;
    align-items: center;
    height: 100%;
}
.user-bar .panel-item {
    padding: 0 10px;
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
}
.user-bar .panel-item i {
    font-size: 16px;
}
.user-bar .panel-item:hover {
    background: rgba(0, 0, 0, 0.1);
}
.user-bar .user {
    display: flex;
    align-items: center;
}
.user-bar .user label {
    display: inline-block;
    margin-left: 5px;
    font-size: 12px;
    cursor: pointer;
}

.msg-list li {
    border-top: 1px solid #eee;
}
.msg-list li a {
    display: flex;
    padding: 20px;
}
.msg-list li a:hover {
    background: #ecf5ff;
}
.msg-list__icon {
    width: 40px;
    margin-right: 15px;
}
.msg-list__main {
    flex: 1;
}
.msg-list__main h2 {
    font-size: 15px;
    font-weight: normal;
    color: #333;
}
.msg-list__main p {
    font-size: 12px;
    color: #999;
    line-height: 1.8;
    margin-top: 5px;
}
.msg-list__time {
    width: 100px;
    text-align: right;
    color: #999;
}
.el-drawer__header {
    padding: 20px 20px 0;
}
</style>
